import React from 'react'
import { Heading, Image, Text, Link, ThemeUIStyleObject } from 'theme-ui'
import Layout from '../components/layout'
import Image404 from '../images/404-image.png'
import { useStaticQuery, graphql } from 'gatsby'

import {
  getPageCollectionData,
  updateSlug,
  generateHelpNavigation,
} from '../utils/navigation'

const headingSx: ThemeUIStyleObject = {
  marginTop: '1.5625rem',
  marginBottom: '0.625rem',
  fontSize: '1.75rem',
  textAlign: 'center',
  fontFamily: 'Arial,Helvetica,sans-serif',
}

const descriptionSx: ThemeUIStyleObject = {
  fontSize: '0.85rem',
  marginY: '0.5rem',
  lineHeight: '1.25rem',
  textAlign: 'center',
  display: 'block',
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
}

const linkSx: ThemeUIStyleObject = {
  ...descriptionSx,
  color: '#3399cc',
  textDecoration: 'none',
}

const imageSx: ThemeUIStyleObject = {
  display: 'block',
  margin: '2rem auto',
}

const NotFound: React.FC = () => {
  const data = useStaticQuery(graphql`
    fragment PageFields on ContentfulPage {
      id
      slug
      title
      navigationTitle
    }

    fragment PageCollectionFields on ContentfulPageCollection {
      id
      slug
      title
    }

    query Pages {
      navigation: contentfulPageCollection(group: { eq: "Navigation" }) {
        pages {
          ... on ContentfulPage {
            ...PageFields
          }
        }
      }
      topFooter: contentfulPageCollection(group: { eq: "Top Footer" }) {
        pages {
          ... on ContentfulPage {
            ...PageFields
          }
        }
      }
      bottomFooter: contentfulPageCollection(group: { eq: "Bottom Footer" }) {
        pages {
          ... on ContentfulPage {
            ...PageFields
          }
          ... on ContentfulPageCollection {
            ...PageCollectionFields
          }
        }
      }
      ourStoryNavigation: contentfulPageCollection(group: { eq: "Our Story" }) {
        pages {
          __typename
          ...PageFields
        }
      }
      helpNavigation: contentfulPageCollection(group: { eq: "Help" }) {
        pages {
          __typename
          ... on ContentfulPageCollection {
            ...PageCollectionFields
            pages {
              __typename
              ... on ContentfulPage {
                ...PageFields
              }
              ... on ContentfulPageCollection {
                ...PageCollectionFields
                pages {
                  __typename
                  ...PageFields
                }
              }
            }
          }
        }
      }
      homePage: contentfulPage(slug: { eq: "home" }) {
        ...PageFields
      }
      ourStoryPage: contentfulPage(slug: { eq: "our-story" }) {
        ...PageFields
        pageHero {
          ...CtaData
        }
      }
      helpPage: contentfulPage(slug: { eq: "help" }) {
        ...PageFields
        pageHero {
          ...CtaData
        }
      }
    }
  `)
  let navigation = data?.navigation?.pages || []

  const footerNavigation = {
    top: data?.topFooter?.pages || [],
    bottom: data?.bottomFooter?.pages || [],
  }
  let helpNavigation = []
  const ourStoryNavigation = data.ourStoryNavigation.pages.map(item => ({
    title: item.title,
    slug: `/${data.ourStoryPage.slug}/${item.slug}`,
  }))

  let toGenerateRedirects = []
  let toGenerateOurStoryPages = []
  let toGenerateHelpPages = []

  if (data.ourStoryNavigation) {
    const ourStoryBasePath = `/${data?.ourStoryPage?.slug || ''}`

    const pageCollectionData = getPageCollectionData(
      data.ourStoryNavigation.pages,
      ourStoryBasePath
    )

    toGenerateOurStoryPages = pageCollectionData.pages
    toGenerateRedirects = [
      ...toGenerateRedirects,
      ...pageCollectionData.redirects,
    ]
  }

  if (data.helpNavigation) {
    const helpBasePath = `/${data?.helpPage?.slug || ''}`

    const pageCollectionData = getPageCollectionData(
      data.helpNavigation.pages,
      helpBasePath
    )

    helpNavigation = generateHelpNavigation(
      data.helpNavigation.pages,
      helpBasePath
    )

    toGenerateHelpPages = pageCollectionData.pages
    toGenerateRedirects = [
      ...toGenerateRedirects,
      ...pageCollectionData.redirects,
    ]
  }

  const toGeneratePages = [...toGenerateOurStoryPages, ...toGenerateHelpPages]

  navigation = navigation.map(item =>
    updateSlug(toGeneratePages, toGenerateRedirects, item)
  )

  footerNavigation.top = footerNavigation.top.map(item =>
    updateSlug(toGeneratePages, toGenerateRedirects, item)
  )

  footerNavigation.bottom = footerNavigation.bottom.map(item =>
    updateSlug(toGeneratePages, toGenerateRedirects, item)
  )

  return (
    <Layout
      page={{ title: '404: Not found' }}
      navigation={navigation}
      footerNavigation={footerNavigation}
      helpNavigation={helpNavigation}
      ourStoryNavigation={ourStoryNavigation}
    >
      <Heading as="h2" sx={headingSx}>
        Oops! Something appears to have gone wrong.
      </Heading>
      <Text sx={descriptionSx}>
        This page has either been deleted, moved or never even existed in the
        first place.
      </Text>
      <Link sx={linkSx} href="/">
        Ecotalk homepage
      </Link>
      <Image sx={imageSx} src={Image404} />
    </Layout>
  )
}

export default NotFound
