export const getPageCollectionData = (
  items,
  baseSlug,
  toGenerate = {
    pages: [],
    redirects: [],
  }
) => {
  items.forEach(item => {
    if (item.__typename === 'ContentfulPage') {
      toGenerate.pages.push({
        id: item.id,
        slug: `${baseSlug}/${item.slug}`,
      })
    }

    if (item.__typename === 'ContentfulPageCollection') {
      if (item.pages && item.pages.length) {
        const foundPage = item.pages.find(
          item => item.__typename === 'ContentfulPage'
        )

        if (foundPage) {
          toGenerate.redirects.push({
            fromPath: `${baseSlug}/${item.slug}`,
            toPath: `${baseSlug}/${item.slug}/${foundPage.slug}`,
            id: item.id,
          })
        }
      }

      toGenerate = getPageCollectionData(
        item.pages,
        `${baseSlug}/${item.slug}`,
        toGenerate
      )
    }
  })

  return toGenerate
}

export const updateSlug = (toGeneratedPages, toGenerateRedirects, item) => {
  const foundPage = toGeneratedPages.find(toGeneratedPage => {
    return toGeneratedPage.id === item.id
  })

  let foundRedirect

  if (!foundPage) {
    foundRedirect = toGenerateRedirects.find(toGenerateRedirect => {
      return toGenerateRedirect.id === item.id
    })
  }

  return {
    ...item,
    slug: foundPage?.slug || foundRedirect?.toPath || `/${item.slug}`,
  }
}

export const generateHelpNavigation = (items, baseSlug) =>
  items.map(item => {
    const itemSlug = `${baseSlug}/${item.slug}`

    if (item.__typename === 'ContentfulPage') {
      return {
        title: item.title,
        slug: itemSlug,
      }
    }

    if (item.__typename === 'ContentfulPageCollection') {
      return {
        title: item.title,
        slug: itemSlug,
        items: generateHelpNavigation(item.pages, itemSlug),
      }
    }
  })
